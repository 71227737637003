import { resolveComponent as _resolveComponent, createVNode as _createVNode, mergeProps as _mergeProps, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_top_filter_button = _resolveComponent("top-filter-button")!
  const _component_dropdown_filter_statuses = _resolveComponent("dropdown-filter-statuses")!
  const _component_dropdown_filter_checkbox = _resolveComponent("dropdown-filter-checkbox")!
  const _component_dropdown_filter_radio = _resolveComponent("dropdown-filter-radio")!
  const _component_dropdown_filter_assignee = _resolveComponent("dropdown-filter-assignee")!
  const _component_top_filter = _resolveComponent("top-filter")!

  return (_openBlock(), _createBlock(_component_top_filter, {
    "show-filters": "",
    "hide-columns-additional-buttons": "",
    "visible-buttons": 5,
    "columns-label": "Campaigns fields",
    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('update:model-value', $event))),
    "onUpdate:radioValue": _cache[4] || (_cache[4] = ($event: any) => (_ctx.$emit('update:radio-value', $event))),
    onReset: _cache[5] || (_cache[5] = ($event: any) => (_ctx.$emit('reset')))
  }, {
    "filter-line-left-default": _withCtx(() => [
      _createVNode(_component_top_filter_button, {
        text: "New campaign",
        icon: "add",
        to: { name: _ctx.flowLink }
      }, null, 8, ["to"])
    ]),
    "filter-line-left-selected": _withCtx(() => [
      _createVNode(_component_top_filter_button, {
        text: "Resume",
        icon: "play_arrow"
      }),
      _createVNode(_component_top_filter_button, {
        text: "Delete",
        icon: "delete"
      })
    ]),
    "filter-line-details": _withCtx(({ updateRadioValue, updateModelValue }) => [
      _createVNode(_component_dropdown_filter_statuses, {
        modelValue: _ctx.filters.campaignStatusesFilter.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.filters.campaignStatusesFilter.value) = $event)),
        options: _ctx.filters.campaignStatusesFilter.options,
        name: "Status",
        "search-placeholder": "Search status",
        removable: false
      }, null, 8, ["modelValue", "options"]),
      _createVNode(_component_dropdown_filter_checkbox, {
        modelValue: _ctx.filters.campaignTypesFilter.value,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.filters.campaignTypesFilter.value) = $event)),
        options: _ctx.filters.campaignTypesFilter.options,
        name: "Type",
        "all-text": "All types"
      }, null, 8, ["modelValue", "options"]),
      _createVNode(_component_dropdown_filter_radio, _mergeProps({
        "model-value": _ctx.filters.nextSendDate.value
      }, _ctx.filters.nextSendDate, {
        name: "Next send date",
        "onUpdate:radioValue": ($event: any) => (updateRadioValue('nextSendDate', $event)),
        "onUpdate:modelValue": ($event: any) => (updateModelValue('nextSendDate', $event))
      }), null, 16, ["model-value", "onUpdate:radioValue", "onUpdate:modelValue"]),
      _createVNode(_component_dropdown_filter_radio, _mergeProps({
        "model-value": _ctx.filters.tags.value
      }, _ctx.filters.tags, {
        name: "Tags",
        "onUpdate:radioValue": ($event: any) => (updateRadioValue('tags', $event)),
        "onUpdate:modelValue": ($event: any) => (updateModelValue('tags', $event))
      }), null, 16, ["model-value", "onUpdate:radioValue", "onUpdate:modelValue"]),
      _createVNode(_component_dropdown_filter_assignee, {
        modelValue: _ctx.filters.createdBy.value,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.filters.createdBy.value) = $event)),
        options: _ctx.filters.createdBy.options,
        label: "Created by",
        "search-placeholder": "Search user"
      }, null, 8, ["modelValue", "options"])
    ]),
    _: 1
  }))
}