
import { computed, defineComponent, ref } from 'vue'
import PageContent from '@/components/layout/pageContent/PageContent.vue'
import CampaignsScheduledFilter from '@/components/pages/campaigns/scheduled/CampaignsScheduledFilter.vue'
import CampaignsScheduledTable from '@/components/pages/campaigns/scheduled/CampaignsScheduledTable.vue'
import type { TableHeaders } from '@/definitions/shared/types'
import { formatDate } from '@/services/dateTimeService'
import { getTableData } from '@/services/tableService'
import { filterItemsByValues } from '@/services/utils'
import type { CampaignType } from '@/definitions/campaigns/types'
import { campaignsScheduledFilters } from '@/definitions/shared/filters/filtersList/data'
import { useFilters } from '@/compositions/filters'

export default defineComponent({
  components: {
    PageContent,
    CampaignsScheduledFilter,
    CampaignsScheduledTable,
  },
  setup() {
    const search = ref('')
    const expand = ref(false)
    const selected = ref()
    const { filters, isDefault, reset } = useFilters(campaignsScheduledFilters)
    const tableHeaders = ref<TableHeaders[]>([
      { text: 'Campaign name', value: 'name-slot' },
      { text: 'Recipients', value: 'recipients-slot' },
      { text: 'Type', value: 'type-slot', width: '100px' },
      { text: 'Message', value: 'message-slot', width: '45%', minWidth: '300px', expandPossible: true },
      { text: 'Status', value: 'status-slot', width: '120px' },
      { text: 'Next send date', value: 'next-send-date-slot', format: (val: string) => formatDate(val) },
    ])
    const tableItems = computed<CampaignType[]>(() => getTableData('campaignsScheduled'))
    const filteredTableItems = computed<CampaignType[]>(
      () => tableItems.value.filter((item) => filterItemsByValues(search.value, [item.name, item.content]))
    )

    return {
      search,
      expand,
      selected,
      filters,
      isDefault,
      reset,
      tableHeaders,
      filteredTableItems,
    }
  },
})
