
import { defineComponent } from 'vue'
import TmTemplate from '@/components/shared/TmTemplate.vue'
import TmTable from '@/components/shared/table/TmTable.vue'
import TmStatus from '@/components/shared/TmStatus.vue'
import TmBadge from '@/components/shared/TmBadge.vue'
import CampaignsScheduledDropdown from '@/components/pages/campaigns/scheduled/CampaignsScheduledDropdown.vue'
import { formatNumber } from '@/services/utils'

export default defineComponent({
  components: {
    TmTemplate,
    TmTable,
    TmStatus,
    TmBadge,
    CampaignsScheduledDropdown,
  },
  setup() {
    return {
      formatNumber,
    }
  },
})
